function events() {
  let events = [];

  function addEvent(type, el, func) {
    el.addEventListener(type, func);
    events.push({
      type,
      el,
      func,
    });
  }

  function removeEvent(el) {
    const index = events.findIndex((ev) => ev.el === el);
    el.removeEventListener(events[index].type, events[index].func, true);
    events.splice(index, 1);
  }

  function removeEvents(...els) {
    els.forEach((el) => removeEvent(el));
  }

  return {
    addEvent,
    removeEvent,
    removeEvents,
  };
}

export default events();
