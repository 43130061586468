import "core-js/stable";
import "regenerator-runtime/runtime";
import "./app.scss";
import Form from "./js/Form";
import "./app.scss";
import Cookies from "js-cookie";
import Popout from "./js/Popout.js";
import Animations from "./js/Animations";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
// import Headroom from "headroom.js";
import Menu from "./js/Menu";
import Slider from "./js/Slider";

let actions = [];
let actionChain = [];
function registerAction(action) {
  actions[action.name] = {
    id: undefined,
    mount: action,
    unmount: undefined,
  };
}

registerAction(Popout);
registerAction(Slider);
registerAction(Animations);
registerAction(Form);
registerAction(Cookies);

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

gsap.registerPlugin(ScrollTrigger);
registerAction(Animations);

runActions();

function addClass(el, className) {
  var el = document.querySelectorAll(el);

  for (i = 0; i < el.length; i++) {
    if (el.classList) {
      el[i].classList.add(className);
    } else {
      el[i].className += " " + className;
    }
  }
}

function runActions() {
  action("Slider", Slider);
  action("Form", Form);
  Animations();
  Menu(document.querySelectorAll(".menu-btn"));

  // const headroomElement = document.querySelector("#main-header");
  // const headroom = new Headroom(headroomElement, {
  //   offset: 20,
  // });
  // headroom.init();
}

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

(() => {
  let mounted = false;
  let service = {
    _open: false,
    overlay: document.querySelector(".overlay"),
    open() {
      this._open = true;

      gsap.to(this.overlay, 0.3, {
        opacity: 1,
      });
      document.body.classList.add("overflow-hidden");
    },
    close() {
      this._open = false;
      document.body.classList.remove("overflow-hidden");
      gsap.to(this.overlay, 0.3, {
        opacity: 0,
        onComplete: () => {
          this.overlay.innerHTML = "";
        },
      });
    },
  };

  window.addEventListener("click", function (evt) {
    if (evt.target.classList.contains("close-modal")) {
      service.close();
    }
  });
})();

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

// document.getElementById("nocursor").style.cursor = "none";

function clearActions() {
  actionChain.forEach((ac) => {
    ac?.unmount?.();
  });
  actionChain = [];
}

function preloader() {
  if (Cookies.get("seen-preloader")) {
    $("#preloader").remove();
  } else {
    Cookies.set("seen-preloader", true);
    setTimeout(() => {
      $(".preloader-img").delay(150).fadeOut("slow");
    }, 3000);
  }
}
