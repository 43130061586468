import Flickity from "flickity";
import events from "./utils/events";

function Slider(el) {
  el.classList.remove("hidden");
  const { addEvent, removeEvents } = events;
  const options = el.dataset?.options ? JSON.parse(el.dataset.options) : {};
  const indexEl = el.parentElement.querySelector("[data-index]");

  const flk = new Flickity(el, options);

  function nextSlide() {
    flk.next();
  }

  flk.on("change", (index) => {
    if (indexEl) indexEl.innerText = index + 1;
  });

  function prevSlide() {
    flk.previous();
  }

  const nextButton = document.querySelector(options?.nextButton);
  if (nextButton) addEvent("click", nextButton, nextSlide);

  const prevButton = document.querySelector(options?.prevButton);
  if (prevButton) addEvent("click", prevButton, prevSlide);

  return () => {
    console.log("destroy slider ");
    removeEvents(nextButton, prevButton);
    flk.destroy();
  };

  const nextButton1 = document.querySelector(options?.nextButton1);
  if (nextButton1) addEvent("click", nextButton1, nextSlide);

  const prevButton1 = document.querySelector(options?.prevButton1);
  if (prevButton1) addEvent("click", prevButton1, prevSlide);

  return () => {
    console.log("destroy slider ");
    removeEvents(nextButton1, prevButton1);
    flk.destroy();
  };
}

export default Slider;

document.onreadystatechange = function () {
  if (document.readyState === "complete") {
    $("#panel_left").addClass("panel_left");
    $("#panel_right").addClass("panel_right");
    $("#loader").addClass("loaded-circle");
    $("#loader-img").addClass("loaded-img");
    $("#preloader").addClass("loaded-img");
  }
};

var divs = $("header"),
  limit = 300; /* scrolltop value when opacity should be 0 */

$(window).on("scroll", function () {
  var st = $(this).scrollTop();

  /* avoid unnecessary call to jQuery function */
  if (st <= limit) {
    divs.css({ opacity: 1 - st / limit });
  }
});
